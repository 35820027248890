import { render, staticRenderFns } from "./fiveBessingsIcon.vue?vue&type=template&id=04d4035d&scoped=true&"
import script from "./fiveBessingsIcon.vue?vue&type=script&lang=js&"
export * from "./fiveBessingsIcon.vue?vue&type=script&lang=js&"
import style0 from "./fiveBessingsIcon.vue?vue&type=style&index=0&id=04d4035d&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d4035d",
  null
  
)

export default component.exports
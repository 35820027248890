<template>
  <div class="b-wapper" v-if="list.length > 2">
    <scrollRow :list="list" :min="3">
      <div class="item-wapper" v-for="(item, index) in list" :key="index" @click="getGameGetUrl(item)">
        <!-- <div class="title-wapper">
          <img src="@/assets/images/jiangbei.png" class="title-icon" />
          <span class="title-txt">{{ $t("大赢家") }}</span>
          <span class="title-time">20秒前</span>
        </div> -->
        <div class="item">
          <img v-if="item.game_icon" v-lazy="item.game_icon" class="cover" />
          <div class="item-content">
            <div class="name-wapper">
              <div class="name" :title="item.username">{{ item.username }}</div>
              <div class="num-wapper">
                {{ numFormat(item.gb_winprice) }}
              </div>
            </div>
            <div class="time-wapper">
              <div class="time">{{ $t("获胜") }} : </div>
              <div class="games-name" :title="$t(item.game_name)">
                {{ $t(item.game_name) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </scrollRow>
  </div>
</template>

<script>
import scrollRow from "@/components/scrollRow.vue";
import { game_login_game_api } from "@/api/cybSports";
export default {
  components: { scrollRow },
  props: ["list"],
  methods: {
    // 获取游戏路径
    async getGameGetUrl(item) {
      if (!this.$store.state.isLoginStatu) {
        this.showLoginReg(1);
        return;
      }
      if (item && item.gb_tcid == 15) {
        this.goUrl("/sport-betting");
        return;
      }
      if (item.is_new_tab === 1) {
        const { data: res } = await game_login_game_api({ platform: this.isMobileFlag ? 2 : 1, gid: item.gb_gid })
        if (res.code === 1) {
          if (res.data.startsWith("http")) {
            this.platUrl(res.data)
          } else {
            var blob = new Blob([res.data], { type: 'text/html' });
            var url = URL.createObjectURL(blob);
            this.platUrl(url)
          }
        } else {
          this.errorTips(res.data.msg)
        }
        return
      }
      this.$router.push({ path: '/game-detail', query: { id: item.gb_gid } })
    },
  }
};
</script>

<style scoped>
.b-wapper {
  color: #FFFFFF;
  margin: -6px 0;
  margin-bottom: 18px;
}

.cover {
  width: 62px;
  height: 62px;
  border-radius: 4px;
  background-color: #000;
  margin-right: 15px;
  object-fit: cover;
}

.games-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.item {
  display: flex;
  align-items: center;
  margin-right: 28px;
  cursor: pointer;
}

.item-content {
  flex: 1;
}

.item-content>div {
  display: flex;
  width: 210px;
  justify-content: space-between;
}

.item-content>div:first-child {
  margin-bottom: 16px;
  font-size: 16px;
}

.num-wapper {
  color: #FFF7B6;
  display: flex;
  align-items: center;
}

.games-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  flex: 1;
  max-width: 175px;
  text-align: right;
}

.name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hs {
  display: none;
}

.title-wapper {
  display: none;
}

.item-wapper {
  cursor: pointer;
  border: 2px solid #FFD52D;
  box-shadow: 0 0 6px #FFD52D, 0 0 10px #FFD52D inset;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  min-width: 230px;
  margin: 6px 0;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .b-wapper {
    background-color: transparent;
    padding: 0;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 10px;
  }

  .cover {
    width: 38px;
    height: 38px;
  }

  .item-content>div:first-child {
    margin-bottom: 0px;
    font-size: 12px;
  }

  .item-content>div {
    display: flex;
    width: auto;
    justify-content: space-between;
  }

  .name {
    margin-right: 20px;
  }

  .name-wapper {
    position: relative;
  }

  .games-logo {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  .item {
    margin-right: 0;
  }

  .hs {
    display: block;
    margin-right: 30px;
    color: #fff;
  }

  .title-wapper {
    display: flex;
    align-items: center;
  }

  .title-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  .title-txt {
    color: #FFF;
    flex: 1;
  }

  .item-wapper {
    border-radius: 5px;
  }
}
</style>
